require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.includes");

require("core-js/modules/es.array.index-of");

require("core-js/modules/es.function.name");

require("core-js/modules/es.number.constructor");

require("core-js/modules/es.object.to-string");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

require("core-js/modules/es.string.split");

require("core-js/modules/web.dom-collections.iterator");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/dialog/style", "vant/es/dialog", "vant/es/icon/style", "vant/es/icon", "vant/es/field/style", "vant/es/field", "vant/es/popup/style", "vant/es/popup"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/dialog/style"), require("vant/es/dialog"), require("vant/es/icon/style"), require("vant/es/icon"), require("vant/es/field/style"), require("vant/es/field"), require("vant/es/popup/style"), require("vant/es/popup"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.dialog, global.style, global.icon, global.style, global.field, global.style, global.popup);
    global.index = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _dialog, _style3, _icon, _style4, _field, _style5, _popup) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _dialog = _interopRequireDefault(_dialog);
  _icon = _interopRequireDefault(_icon);
  _field = _interopRequireDefault(_field);
  _popup = _interopRequireDefault(_popup);
  var _default = {
    name: "FactoryIndex",
    components: {
      Popup: _popup.default,
      Field: _field.default,
      Icon: _icon.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        name: "",
        meta: {
          title: '1234'
        },
        // 乐行： 2
        // 瑞银： 1
        type: 0,
        //  待质检数量
        count: 0,
        showModal: false,
        probeId: '',
        // 库房：1
        yType: '',
        showSetupModal: false,
        // 设备质检信息
        qaInfo: {},
        showReQuality: false
      };
    },
    computed: {
      isPass: function isPass() {
        return Number(this.probeId) > 0 && this.probeId.length > 6;
      }
    },
    created: function created() {
      var vm = this;
      var USER_NAME = vm.$config.keys.USER_NAME;
      var name = vm.$localStorage.getItem(USER_NAME);
      var yType = vm.$route.query.yType;
      var uuid = vm.$route.query.uuid || vm.$localStorage.getItem('uuid');
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (vm.$route.query.uuid) {
        vm.$localStorage.setItem('uuid', vm.$route.query.uuid);
      }

      if (name && fType) {
        vm.name = name;

        if (["lexing", "ruiyin", "yingwo", "lansong", "wangbo"].indexOf(fType) == -1) {
          vm.$router.replace({
            path: "404"
          });
          return;
        } else {
          var type = 0;
          fType == 'ruiyin' && (type = 1);
          fType == 'yingwo' && (type = 2);
          fType == 'lexing' && (type = 3);
          fType == 'lansong' && (type = 4);
          fType == 'wangbo' && (type = 5);
          vm.type = type;
          [1, 3, 5].indexOf(type) > -1 && vm.loadCount();
          window.fTypeText = fType;
          window.fType = type;
        }
      } else {
        vm.$localStorage.setItem(USER_NAME, "");
        vm.$localStorage.setItem("uuid", "");
        vm.$router.replace({
          path: "404"
        });
      }

      vm.yType = yType;
      vm.weixinInit();
    },
    methods: {
      switchAccount: function switchAccount() {
        var vm = this;

        _dialog.default.confirm({
          title: "提示",
          message: "是否确认退出当前账号？",
          confirmButtonColor: "#32BE32"
        }).then(function () {
          var USER_NAME = vm.$config.keys.USER_NAME;
          vm.$localStorage.removeItem(USER_NAME);
          vm.$router.replace({
            path: "/factory/login"
          });
        }).catch(function () {// on cancel
        });
      },
      loadProbeInfo: function loadProbeInfo() {
        var vm = this;

        if (!vm.isPass) {
          return;
        }

        vm.tt = _toast.default.loading({
          message: '加载中',
          mask: true
        });
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "careInfo"),
          params: {
            probeId: vm.probeId
          }
        }).then(function (res) {
          vm.tt.clear();

          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          if (res.data.status != 1) {
            _toast.default.fail('该探头暂无待复核状态订单');

            return;
          }

          vm.showModal = false;
          vm.$router.push({
            path: "./afterSale/determineRes",
            query: {
              pId: res.data.id
            }
          });
        });
      },
      weixinInit: function weixinInit() {
        var vm = this;
        vm.$http({
          type: "get",
          url: "https://m.yudada.com/jssdk/configuration?url=".concat(location.href)
        }).then(function (res) {
          var _res$data = res.data,
              appId = _res$data.appId,
              nonceStr = _res$data.nonceStr,
              signature = _res$data.signature,
              timestamp = _res$data.timestamp;
          window.wx.config({
            debug: false,
            appId: appId,
            timestamp: timestamp,
            nonceStr: nonceStr,
            signature: signature,
            jsApiList: ["scanQRCode"]
          });
        });
      },
      vieProbeList: function vieProbeList() {
        this.$router.push({
          path: "/ryProbe/list"
        });
      },
      viewWarningList: function viewWarningList() {
        this.$router.push({
          path: "./afterSale/acceptanceCheckWarningList"
        });
      },
      scan: function scan() {
        var vm = this;
        window.wx.scanQRCode({
          needResult: 1,
          scanType: ["qrCode"],
          success: function success(res) {
            var result = res.resultStr;
            var uuid = result.split('deviceId=')[1];

            if (!uuid) {
              _dialog.default.confirm({
                title: "提示",
                message: "非设备二维码~",
                confirmButtonColor: "#32BE32",
                showCancelButton: false
              });

              return;
            } // 旺博投料机控制盒质检操作


            if (window.fType == 5) {
              vm.loadDeviceInfo(encodeURI(uuid));
              return;
            }

            vm.$router.push({
              path: "/factory/checkInfo",
              query: {
                uuid: encodeURI(uuid)
              }
            });
          }
        });
      },
      // 扫码获取设备信息
      loadDeviceInfo: function loadDeviceInfo(uuid) {
        var vm = this;
        vm.uuid = uuid;
        vm.tt = _toast.default.loading({
          message: '加载中',
          mask: true
        });
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "device/info"),
          params: {
            encodeId: vm.uuid
          }
        }).then(function (res) {
          vm.tt.clear();
          var _res$data2 = res.data,
              type = _res$data2.type,
              uniqueId = _res$data2.uniqueId,
              deviceId = _res$data2.deviceId,
              hasKzhQc = _res$data2.hasKzhQc;
          vm.qaInfo = res.data; // 风送投料机

          if ([40, 41, 42, 43].includes(type)) {
            // 质检过的设备弹框提醒
            if (hasKzhQc) {
              vm.showReQuality = true;
              return;
            }

            vm.$router.push({
              path: "/factory/quality/tlQa/".concat(deviceId, "?uid=").concat(uniqueId, "&isFs=1&qaType=1")
            });
            return;
          }

          vm.showSetupModal = true;
        });
      },
      // 重新质检风送控制盒
      onclickResetFsBtn: function onclickResetFsBtn() {
        var vm = this;
        var _vm$qaInfo = vm.qaInfo,
            uniqueId = _vm$qaInfo.uniqueId,
            deviceId = _vm$qaInfo.deviceId;
        vm.$router.push({
          path: "/factory/quality/tlQa/".concat(deviceId, "?uid=").concat(uniqueId, "&isFs=1&qaType=1")
        });
      },
      // 重新质检 type: 1控制盒,2称重质检
      onclickResetQABtn: function onclickResetQABtn(type, status) {
        var vm = this;
        var _vm$qaInfo2 = vm.qaInfo,
            uniqueId = _vm$qaInfo2.uniqueId,
            deviceId = _vm$qaInfo2.deviceId; // 重新检测，先清除质检状态

        if (status) {
          _dialog.default.confirm({
            title: "提示",
            message: "是否需要重新质检？",
            confirmButtonColor: "#32BE32"
          }).then(function () {
            vm.tt = _toast.default.loading({
              message: '加载中',
              mask: true
            });
            vm.$http({
              type: "put",
              data: {
                password: vm.$config.base.password,
                userName: vm.name,
                deviceIds: [deviceId],
                operation: 1,
                type: type
              },
              url: "".concat(vm.$config.base.PROBE_BASE_URL, "tl/kzh/qualityCheck"),
              params: {
                encodeId: vm.uuid
              }
            }).then(function () {
              vm.tt.clear();
              vm.$router.push({
                path: "/factory/quality/tlQa/".concat(deviceId, "?uid=").concat(uniqueId, "&qaType=").concat(type)
              });
            });
          }).catch(function () {// on cancel
          });
        } else {
          vm.$router.push({
            path: "/factory/quality/tlQa/".concat(deviceId, "?uid=").concat(uniqueId, "&qaType=").concat(type)
          });
        }
      },
      viewDescription: function viewDescription() {
        this.$router.push({
          path: "/factory/description?type=".concat(this.type)
        });
      },
      viewQualityList: function viewQualityList(operation) {
        // 是否为返厂定损
        if (operation == 1) {
          window.isLoss = true;
        } else {
          window.isLoss = false;
        }

        this.$router.push({
          path: this.type == 2 ? "/factory/afterSale/uploadImgPage" : "/factory/quality/qualityList"
        });
      },
      loadCount: function loadCount() {
        var vm = this;
        var deviceType = vm.type; // 投料机控制盒

        vm.type == 5 && (deviceType = 2);
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "damageCount"),
          params: {
            deviceType: deviceType
          }
        }).then(function (res) {
          vm.count = res.data;
        });
      },
      onclickOutStock: function onclickOutStock() {
        this.$router.push({
          path: './stock/checkDevice'
        });
      }
    }
  };
  _exports.default = _default;
});